
import { onMounted, reactive, ref, toRefs, nextTick } from "vue";
import { ElForm } from "element-plus";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import {
  getAcademies,
  getMajors,
  getDirections,
  uploadImg,
  getPostgraList,
  updatePost,
  deletePost,
  regexPos,
  createPost,
  isPass,
  avatarList
} from "../config/interface";
type FormInstance = InstanceType<typeof ElForm>;
// type InputInstance = InstanceType<typeof ElInput>
const util = require("../utils/util");
export default {
  components: {
    Plus,
  },
  setup() {
    const ruleFormRef = ref<FormInstance>();
    // const InputRef = ref<InputInstance>();
    const InputRef = ref(null);
    let data = reactive({
      tableData: [],
      search: {
        name: "",
        college: "",
        major: "",
      }, // 查询参数
      currentPage: 1, // 当前页数
      pageSize: 10, // 每页显示数量
      totalPage: 10,
      popStatus: 0, // 弹框状态，0为新增，1为编辑
      inputFocus: false, // 院校输入框聚焦状态，控制联想内容框显示
      dialogVisible: false, // 控制新增对话框状态
      dialogVisible2: false,
      isShowAvatarPop: false,
      temp: "姓名：\n性别：\n联系方式：\n微信号：\n院校：\n院系：\n专业：\n方向：\n入学时间：\n政治分数：\n英语分数：\n专业课1：\n专业课2：\n复试成绩：\n上岸途径：\n辅导经验：\n渠道：",
      // 临时存放研究生信息
      userInfo: {
        id: "",
        name: "",
        avatar: "",
        avatarId: "",
        sex: "",
        contact: "",
        wechatNum: "",
        college: "",
        academy: "",
        major: "",
        direction: "",
        entranceTime: "",
        polScore: "",
        engScore: "",
        zykOne: "",
        zykTwo: "",
        totalScore: "",
        retestScore: "",
        successWay: "",
        guideExperience: "",
        channel: "",
        remark: "",
        studentIdCard: "",
        reportCard: "",
        offer: "",
        preOffer: "",
        nickname: "",
        englishName: "",
        mathName: "",
        zyk1Name: "",
        zyk2Name: "",
        initialRank: "",
        againRank: "",
        totalRank: "",
        zykService: "",
        mathService: "",
        politicsService: "",
        englishService: "",
        otherService: "",
        availInfo: "",
        tutorAdvantage: "",
        tag: [] as any,
      },
      // 表单验证规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入研究生姓名",
            trigger: "blur",
          },
        ],
        sex: [
          {
            required: true,
            message: "请选择研究生性别",
            trigger: "change",
          },
        ],
        contact: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            min: 11,
            max: 11,
            message: "手机号码应为11位数",
            trigger: "blur",
          },
        ],
        wechatNum: [
          {
            required: true,
            message: "请输入微信号",
            trigger: "blur",
          },
        ],
        college: [
          {
            required: true,
            message: "请输入院校",
            trigger: "blur",
          },
        ],
        academy: [
          {
            required: true,
            message: "请选择院系",
            trigger: "blur",
          },
        ],
        major: [
          {
            required: true,
            message: "请选择专业",
            trigger: "blur",
          },
        ],
        direction: [
          // {
          //   required: true,
          //   message: "请选择方向",
          //   trigger: "blur",
          // },
        ],
        entranceTime: [
          {
            type: "date",
            required: true,
            message: "请选择入学时间",
            trigger: "blur",
          },
        ],
        polScore: [
          {
            required: true,
            message: "请输入政治成绩",
            trigger: "blur",
          },
        ],
        engScore: [
          {
            required: true,
            message: "请输入英语成绩",
            trigger: "blur",
          },
        ],
        zykOne: [
          {
            required: true,
            message: "请输入专业课一成绩",
            trigger: "blur",
          },
        ],
        zykTwo: [
          {
            required: true,
            message: "请输入专业课二成绩",
            trigger: "blur",
          },
        ],
        totalScore: [
          {
            required: true,
            message: "请输入总分",
            trigger: "blur",
          },
        ],
        retestScore: [
          {
            required: true,
            message: "请输入复试成绩",
            trigger: "blur",
          },
        ],
        successWay: [
          {
            required: true,
            message: "请选择上岸途径",
            trigger: "blur",
          },
        ],
        guideExperience: [
          {
            required: true,
            message: "是否有过辅导经验",
            trigger: "blur",
          },
        ],
        channel: [
          {
            required: true,
            message: "请选择渠道",
            trigger: "blur",
          },
        ],
      },
      selectCollege: [{}], // 院校联想框内容
      isShow: false, // 院校联想框状态
      collegeArr: require("../assets/university.json"),
      tableLoading: false,
      directionArr: [{}] as any, // 方向列表
      btnLoading: false, // 智能识别按钮的loading状态
      quickCont: "", // 智能识别输入框中的内容
      cardFile: [] as any[], // 学生证url
      reportFile: [] as any[], // 成绩单url
      editBtn: false,
      collegeCode: 0, // 院校编号
      academyArr: [{}] as any, // 院系下拉列表
      academyCode: 0, // 院系编号
      majorArr: [{}] as any, // 专业下拉列表
      majorCode: 0, // 专业编号
      inputValue: "",
      inputVisible: false,
      tagList: [] as any,
      imgList: [] as any,
      exportTableData: [],
      exportFieldKeys: ['id', 'name', 'sex', 'contact', 'wechatNum', 'college', 'academy', 'major', 'direction', 'entranceTime', 'polScore', 'engScore', 'mathScore', 'zykOne', 'zykTwo', 'totalScore', 'initialRank', 'againRank', 'totalRank', 'retestScore', 'successWay', 'guideExperience', 'channel', 'zykService', 'mathService', 'politicsService', 'englishService', 'otherService', 'availInfo', 'tutorAdvantage', 'tag', 'remark', 'studentIdCard', 'offer', 'preOffer', 'isPass'],
      exportFieldLabels: ['ID', '姓名', '性别', '手机号码', '微信', '院校', '院系', '专业', '方向', '入学时间', '政治', '英语', '数学', '专业课1', '专业课2', '总分', '初试排名', '复试排名', '总分排名', '复试成绩', '上岸途径', '辅导经验', '渠道', '专业课服务', '数学服务', '政治服务', '英语服务', '其他服务', '可提供资料', '辅导优势', '标签', '备注', '学生证', '成绩单', '录取通知书', '拟录取截图', '发布状态'],
      exportFileName: '研究生信息'
    });

    const handleExport = () => {
      let exportTableData: any = data.tableData
      let dataStr = data.exportFieldLabels.toString() + '\r\n';
      exportTableData.forEach((item: any) => {
        item['sex'] = item['sex'] == 1 ? '男' : '女'
        if (item['sex'] == 1) {
          item['sex'] = '男'
        } else if (item['sex'] == 2) {
          item['sex'] = '女'
        }
      });
      exportTableData.forEach((item: any) => {
        data.exportFieldKeys.forEach(key => {
          // 加引号是为了使换行符在单元格内正常显示 
          dataStr += `"${item[key]}"\t,`;
        });
        dataStr += '\r\n';
      });
      console.log(exportTableData);

      // encodeURIComponent 解决中文乱码
      const url = "data:text/xls;charset=utf-8,\ufeff" + encodeURIComponent(dataStr);
      const link = document.createElement("a");
      link.href = url;
      link.download = data.exportFileName + ".xls";
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); //释放标签
    };
    const handleCopyTemp = () => {
      data.dialogVisible2 = true;
      // const input = document.createElement("input");
      // document.body.appendChild(input);
      // let params = "姓名：性别：联系方式：微信号：院校：院系：专业：方向：入学时间：政治分数：英语分数：专业课1：专业课2：复试成绩：上岸途径：辅导经验：渠道：";
      // input.setAttribute("value", params);
      // input.select();
      // if (document.execCommand("copy")) {
      // 	document.execCommand("copy");
      // 	ElMessage.success("复制成功");
      // }
      // document.body.removeChild(input);
    };
    // 新增或修改对话框里的上岸途径下拉框
    const handleSelectCoach = (val: any) => {
      data.userInfo.guideExperience = val;
    };
    // 新增或修改对话框里的上岸途径下拉框
    const handleSelectRoad = (val: any) => {
      data.userInfo.successWay = val;
    };
    // 表格中的辅导经验下拉框，选择后请求接口改变状态
    const handleChangeCoach = (val: any) => {
      console.log(val);
    };
    // 表格中的上岸途径下拉框，选择后请求接口改变状态
    const handleChangeRoad = (val: any) => {
      console.log(val);
    };
    // 点击新建按钮，填写表单
    const handleCreate = () => {
      data.userInfo = {
        id: "",
        name: "",
        avatar: "",
        avatarId: "",
        sex: "",
        contact: "",
        wechatNum: "",
        college: "",
        academy: "",
        major: "",
        direction: "",
        entranceTime: "",
        polScore: "",
        engScore: "",
        zykOne: "",
        zykTwo: "",
        totalScore: "",
        retestScore: "",
        successWay: "",
        guideExperience: "",
        channel: "",
        remark: "",
        studentIdCard: "",
        reportCard: "",
        offer: "",
        preOffer: "",
        nickname: "",
        englishName: "",
        mathName: "",
        zyk1Name: "",
        zyk2Name: "",
        initialRank: "",
        againRank: "",
        totalRank: "",
        zykService: "",
        mathService: "",
        politicsService: "",
        englishService: "",
        otherService: "",
        availInfo: "",
        tutorAdvantage: "",
        tag: [] as any
      };
      data.cardFile = [];
      data.reportFile = [];
      data.quickCont = "";
      data.popStatus = 0;
      data.dialogVisible = true;
    };
    // 新增弹框内的上传成绩单
    const uploadReportFile = (file: any) => {
      const form = new FormData();
      form.append("file", file.file);
      form.append("classfication", "2");
      uploadImg(form).then((res) => {
        if (res.data.success) {
          data.userInfo.reportCard = res.data.data;
          ElMessage.success("上传成功");
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    const uploadOfferFile = (file: any) => {
      const form = new FormData();
      form.append("file", file.file);
      form.append("classfication", "3");
      uploadImg(form).then((res) => {
        if (res.data.success) {
          data.userInfo.offer = res.data.data;
          ElMessage.success("上传成功");
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    const uploadPreOfferFile = (file: any) => {
      const form = new FormData();
      form.append("file", file.file);
      form.append("classfication", "4");
      uploadImg(form).then((res) => {
        if (res.data.success) {
          data.userInfo.preOffer = res.data.data;
          ElMessage.success("上传成功");
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    // 点击智能识别按钮，请求接口
    const handleDistinguish = () => {
      data.btnLoading = true;
      let params = {
        macthContent: data.quickCont,
      };
      data.btnLoading = false;
      regexPos(params).then((res) => {
        console.log(res);
        if (res.data.data && res.data.success) {
          data.userInfo = res.data.data;
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    // 新增弹框内的上传学生证
    const uploadCardFile = (file: any) => {
      const form = new FormData();
      form.append("file", file.file);
      form.append("classfication", "1");
      for (var value of form.values()) {
        console.log(value);
      }
      uploadImg(form).then((res) => {
        console.log(res);
        if (res.data.success) {
          data.userInfo.studentIdCard = res.data.data;
          ElMessage.success("上传成功");
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };

    // 选择院校
    const handleSelectCollege = (val: string) => {
      data.collegeArr.university.map((item: any) => {
        if (item.name === val) {
          data.collegeCode = item.code;
        }
      });
      data.userInfo.academy = "";
      data.userInfo.major = "";
      data.userInfo.direction = "";
      getAcademies({ collegeCode: data.collegeCode }).then((res) => {
        if (res.data.errorCode == 200) {
          if (res.data.data.length) {
            data.academyArr = res.data.data;
          } else {
            ElMessage.error("暂未查询到该学院的院系");
          }
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    // 选择院系
    const handleSelectAcademy = (val: string) => {
      data.academyArr.map((item: any) => {
        if (item.academyName === val) {
          data.academyCode = item.academyCode;
        }
      });
      data.userInfo.major = "";
      data.userInfo.direction = "";
      getMajors({
        collegeCode: data.collegeCode,
        academyCode: data.academyCode,
      }).then((res) => {
        if (res.data.errorCode == 200) {
          if (res.data.data.length) {
            data.majorArr = res.data.data;
          } else {
            ElMessage.error("暂未查询到该院系的专业");
          }
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    // 选择专业
    const handleSelectMajor = (val: string) => {
      data.majorArr.map((item: any) => {
        if (item.majorName === val) {
          data.majorCode = item.majorCode;
        }
      });
      data.userInfo.direction = "";
      let params = {
        collegeCode: data.collegeCode,
        academyCode: data.academyCode,
        majorCode: data.majorCode,
      };
      getDirections(params).then((res) => {
        if (res.data.errorCode == 200) {
          if (res.data.data.length) {
            data.directionArr = res.data.data;
          } else {
            ElMessage.error("暂未查询到该专业的方向");
          }
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };

    // 新增或修改信息的确认按钮
    const handleInsert = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      let date = util.formatTimer(data.userInfo.entranceTime);
      data.userInfo.entranceTime = date;
      formEl.validate((valid) => {
        data.editBtn = true;
        console.log(valid);

        if (valid && data.popStatus === 0) {
          // 新建研究生信息
          console.log(data.userInfo.tag);
          let tagString = ''
          data.userInfo.tag.map((item: any) => {
            tagString += `${item}-`
          })
          tagString = tagString.slice(0, -1)
          console.log(tagString);
          data.userInfo.tag = tagString
          createPost(data.userInfo).then((res) => {
            if (res.data.data && res.data.success) {
              data.editBtn = false;
              data.dialogVisible = false;
              getPostList(
                1,
                data.search.name,
                data.search.college,
                data.search.major,
                data.pageSize
              );
              ElMessage.success(res.data.data);
            } else {
              data.editBtn = false;
              data.dialogVisible = false;
              ElMessage.error(res.data.msg);
            }
          });
        } else if (valid && data.popStatus === 1) {
          // 编辑研究生信息
          let tag = '';
          data.userInfo.tag.map((item: any) => {
            console.log(item);
            tag += item + '-'
          })
          tag = tag.substring(0, tag.lastIndexOf('-'));
          data.userInfo.tag = tag;

          updatePost(data.userInfo).then((res) => {
            if (res.data.data && res.data.success) {
              data.editBtn = false;
              data.dialogVisible = false;
              getPostList(
                1,
                data.search.name,
                data.search.college,
                data.search.major,
                data.pageSize
              );
              ElMessage.success(res.data.data);
            } else {
              data.editBtn = false;
              data.dialogVisible = false;
              ElMessage.error(res.data.msg);
            }
          });
        } else {
          data.editBtn = false;
          console.log("error submit!");
          return false;
        }
      });
    };
    const show = () => {
      console.log(1);
    };
    // 点击学生证和成绩单的查看详情,打开浏览器新标签栏,展示图片
    const showDetail = (url: string) => {
      window.open(url);
    };
    const handlePass = (item: any) => {
      isPass({ id: item.id, isPass: 1 }).then((res: any) => {
        console.log();
        if (res.data.code === 200 && res.data.status) {
          ElMessage.success('发布成功')
          getPostList(data.currentPage, data.search.name, data.search.college, data.search.major, data.pageSize)
        } else {
          ElMessage.error('发布失败')
        }
      })
    }
    // 点击编辑按钮,弹出修改弹框
    const handleEdit = (item: any) => {
      let temp = JSON.parse(JSON.stringify(item));
      console.log(item);
      data.popStatus = 1;
      temp.tag = stringToArr(temp.tag);
      data.userInfo = temp;
      data.dialogVisible = true;
      if (item.studentIdCard) {
        data.cardFile = [{ url: item.studentIdCard }];
      }
      if (item.reportCard) {
        data.reportFile = [{ url: item.reportCard }];
      }
      data.academyArr = [];
      data.majorArr = [];
      data.directionArr = [];
      // 根据院校名称，查询对应的院校code
      data.collegeArr.university.map((item: any) => {
        if (item.name === data.userInfo.college) {
          data.collegeCode = item.code;
        }
      });
      // 根据院校code，查询对应的院系列表
      getAcademies({ collegeCode: data.collegeCode }).then((res) => {
        if (res.data.errorCode == 200) {
          if (res.data.data.length) {
            data.academyArr = res.data.data;
            // 根据院系名称，查询对应的院系code
            data.academyArr.map((item: any) => {
              if (item.academyName == data.userInfo.academy) {
                data.academyCode = item.academyCode;
                // 根据院系code，查询对应的专业列表
                getMajors({
                  collegeCode: data.collegeCode,
                  academyCode: data.academyCode,
                }).then((res) => {
                  if (res.data.errorCode == 200) {
                    if (res.data.data.length) {
                      data.majorArr = res.data.data;
                      // 根据专业名称，查询对应的专业code
                      data.majorArr.map((item: any) => {
                        if (item.majorName == data.userInfo.major) {
                          data.majorCode = item.majorCode;
                          let params = {
                            collegeCode: data.collegeCode,
                            academyCode: data.academyCode,
                            majorCode: data.majorCode,
                          };
                          // 根据专业code，查询方向列表
                          getDirections(params).then((res) => {
                            if (res.data.errorCode == 200) {
                              if (res.data.data.length) {
                                data.directionArr = res.data.data;
                              } else {
                                ElMessage.error("暂未查询到该专业的方向");
                              }
                            } else {
                              ElMessage.error(res.data.msg);
                            }
                          });
                        }
                      });
                    } else {
                      ElMessage.error("暂未查询到该院系的专业");
                    }
                  } else {
                    ElMessage.error(res.data.msg);
                  }
                });
              }
            });
          } else {
            ElMessage.error("暂未查询到该学院的院系");
          }
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    // 点击删除按钮,请求删除接口
    const handleDelete = (pId: number) => {
      deletePost({ pId }).then((res) => {
        console.log(res);
        if (res.data.data && res.data.success) {
          getPostList(
            1,
            data.search.name,
            data.search.college,
            data.search.major,
            data.pageSize
          );
          ElMessage.success(res.data.data);
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    // 点击搜索按钮,请求查询接口
    const handleSearch = () => {
      console.log("查询成功");
      console.log(data.search);
      getPostList(1, data.search.name, data.search.college, data.search.major, data.pageSize);
    };
    // 改变每页数量,请求列表数据接口
    const handleSizeChange = (val: number) => {
      console.log(`${val} items per page`);
      data.pageSize = val;
    };
    // 点击分页按钮,请求列表数据接口
    const handleCurrentChange = (val: number) => {
      data.currentPage = val;
      getPostList(
        val,
        data.search.name,
        data.search.college,
        data.search.major,
        data.pageSize
      );
    };
    // 点击院校联系框的内容，将值赋予
    const handleSelect = (value: any) => {
      // 选中院校后，将院校名称和code赋予，然后清空院系、专业、方向
      data.isShow = false;
      data.userInfo.college = value.name;
      data.collegeCode = value.code;
      data.userInfo.academy = "";
      data.userInfo.major = "";
      data.userInfo.direction = "";
      data.academyCode = 0;
      data.majorCode = 0;
      data.academyArr = [];
      data.majorArr = [];
      data.directionArr = [];
      getAcademies({ collegeCode: value.code }).then((res) => {
        if (res.status == 200) {
          if (res.data.errorCode == 200) {
            if (res.data.data.length) {
              data.academyArr = res.data.data;
            } else {
              ElMessage.error("暂未查询到该学院的院系");
            }
          } else {
            ElMessage.error(res.data.msg);
          }
        } else {
          ElMessage.error(res.statusText);
        }
      });
    };
    // 清空院校联系内容并关闭
    const handleClose = () => {
      data.selectCollege = [];
      data.isShow = false;
      data.isShowAvatarPop = false

    };
    const getPostList = (
      pageNum: number,
      name: string,
      college: string,
      major: string,
      pageSize: number
    ) => {
      data.tableLoading = true;
      let params = { pageNum, name, college, major, pageSize };
      getPostgraList(params).then((res: any) => {
        if (res.data && res.data.success) {
          data.tableData = res.data.data.list;
          data.totalPage = res.data.data.total;
          data.tableLoading = false;
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    const stringToArr = (string: any) => {
      if (string) {
        let arr = string.split("-");
        return arr;
      } else {
        return [];
      }
    };
    const handleCloseTag = (tag: any) => {
      data.userInfo.tag.splice(data.userInfo.tag.indexOf(tag), 1);
    };
    const showInput = () => {
      data.inputVisible = true;
      nextTick(() => {
        console.log(InputRef);
        // InputRef.value!.input!.focus();
      });
    };
    const handleInputConfirm = () => {
      console.log(data.inputValue);
      // console.log(data.userInfo.tag);
      // if(data.inputValue) {
      // 	data.tagList.push(data.inputValue)
      // }
      // console.log(data.tagList);

      if (data.inputValue) {
        data.userInfo.tag.push(data.inputValue);
      }
      data.inputVisible = false;
      data.inputValue = "";
      // console.log(data.userInfo);

    };
    const handleShowAvatarPop = () => {
      data.isShowAvatarPop = !data.isShowAvatarPop
    }
    const handleSelectAvatar = (item: any) => {
      data.userInfo.avatar = item.avatar
      data.userInfo.avatarId = item.id
      data.isShowAvatarPop = false
    }
    const handleCloseAvatarPop = () => {
      console.log(1);
      data.isShowAvatarPop = false
    }
    const getAvatarList = () => {
      avatarList({}).then((res: any) => {
        data.imgList = res.data.data
      })
    }
    const closeForm = () => {
      data.dialogVisible = false
      data.isShowAvatarPop = false
    }
    onMounted(() => {
      getPostList(1, data.search.name, data.search.college, data.search.major, data.pageSize);
      getAvatarList()
    });
    return {
      ...toRefs(data),
      ruleFormRef,
      show,
      handleSelectCoach,
      handleSelectRoad,
      handleChangeCoach,
      handleChangeRoad,
      handleCreate,
      uploadCardFile,
      uploadReportFile,
      handleDistinguish,
      handleSelectMajor,
      handleSelectAcademy,
      handleInsert,
      showDetail,
      handleEdit,
      handleDelete,
      handleSearch,
      handleSizeChange,
      handleCurrentChange,
      handleSelect,
      handleClose,
      handleSelectCollege,
      handleCopyTemp,
      uploadOfferFile,
      uploadPreOfferFile,
      stringToArr,
      handleCloseTag,
      showInput,
      handleInputConfirm,
      handlePass,
      handleShowAvatarPop,
      handleCloseAvatarPop,
      handleSelectAvatar,
      closeForm,
      handleExport
    };
  },
};
