const formatTimer = ((value: any) => {
  // value是计算属性执行后，再次执行return里面的函数时传的参数
  const date = new Date(value);
  const y = date.getFullYear(); //年
  const mm = date.getMonth() + 1;
  const MM = mm < 10 ? "0" + mm : mm; //月
  const dd = date.getDate();
  const d = dd < 10 ? "0" + dd : dd; //日
  const hs = date.getHours();
  const h = hs < 10 ? "0" + hs : hs; //时
  const ms = date.getMinutes();
  const m = ms < 10 ? "0" + ms : ms; //分
  const ss = date.getSeconds();
  const s = ss < 10 ? "0" + ss : ss; //秒
  return y + "-" + MM + "-" + d + " " + h + ":" + m + ':' + s;
})

const formatMinutes = ((value: any) => {
  // value是计算属性执行后，再次执行return里面的函数时传的参数
  const date = new Date(value);
  const ms = date.getMinutes();
  return ms;
})

module.exports = {
  formatTimer: formatTimer,
  formatMinutes:formatMinutes
}